<template>
  <div class="relative w-full">
    <textarea
      :rows="rows"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      v-bind="$attrs"
      :disabled="disabled"
      class="primary-input w-full resize-none"
      :class="{
        'cursor-not-allowed': disabled,
        'animate-pulse': loading,
        'focus:!ring-red-500 !border-red-500 dark:focus:!ring-red-500 dark:focus:!border-red-500 border dark:text-white':
          hasError,
      }"
    />
    <div
      class="absolute bottom-[5px] w-full h-[1px] max-w-[95%] left-1/2 -translate-x-1/2 cursor-row-resize bg-transparent group hover:bg-gray-400 dark:hover:bg-gray-600 flex items-center justify-center"
      @mousedown="startResizing"
    >
      <div
        class="rounded-full h-1 w-[80px] max-w-[50%] bg-gray-300 dark:bg-gray-700 group-hover:bg-gray-500 absolute -top-0.5"
        :class="thumbClass"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ResizableTextarea',
    props: {
      modelValue: String,
      placeholder: {
        type: String,
        default: 'Enter your text here...',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      minRows: {
        type: Number,
        default: 2,
      },
      maxRows: {
        type: Number,
        default: 50,
      },
      hasError: {
        type: Boolean,
        default: () => false,
      },
      thumbClass: {
        type: String,
        default: 'bg-gray-300 dark:bg-gray-700',
      },
    },
    data() {
      return {
        rows: this.minRows,
        initialY: 0,
        initialRows: this.minRows,
        isResizing: false,
      }
    },
    methods: {
      startResizing(event) {
        this.isResizing = true
        this.initialY = event.clientY
        this.initialRows = this.rows
        window.addEventListener('mousemove', this.resize)
        window.addEventListener('mouseup', this.stopResizing)
      },
      resize(event) {
        const diffY = event.clientY - this.initialY
        const rowHeight = 24 // Approximate row height in pixels (adjust as necessary)
        let newRows = this.initialRows + Math.floor(diffY / rowHeight)
        newRows = Math.max(this.minRows, Math.min(newRows, this.maxRows))
        this.rows = newRows
      },
      stopResizing() {
        this.isResizing = false
        window.removeEventListener('mousemove', this.resize)
        window.removeEventListener('mouseup', this.stopResizing)

        // click the textarea to remove focus from the resize handle
        this.$el.querySelector('textarea')?.focus()
      },
    },
  }
</script>
