<template>
  <section v-if="page === 'email'" class="bg-white dark:bg-[#141414] w-full text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="mb-6">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="space-y-4 md:space-y-6">
          <h1 class="text-24 font-bold text-center">Password Reset</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="primary-label">Your email</label>
              <input
                type="email"
                name="email"
                id="email"
                class="primary-input"
                placeholder="name@company.com"
                v-model="email"
                required
              />
            </div>
            <button @click.prevent="reset" class="primary-btn !w-full !font-medium" :disabled="requesting_reset_token">
              <LoadingIcon
                v-if="requesting_reset_token"
                classList="w-6 h-6 text-white dark:text-gray-400 animate-spin"
              />
              Send Reset Token
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section v-else-if="page === 'verify'" class="bg-white dark:bg-[#141414] w-full text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="space-y-4 md:space-y-6">
          <h1 class="text-24 font-bold text-center">Password Reset</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="password" class="primary-label">New password</label>
              <input
                type="password"
                name="password"
                id="password"
                class="primary-input"
                placeholder="Password"
                v-model="password"
                required=""
              />
            </div>

            <div>
              <label for="token" class="primary-label">Reset Token</label>
              <input
                type="text"
                name="token"
                id="token"
                class="primary-input"
                placeholder="123456"
                v-model="token"
                required=""
              />
            </div>
            <button @click.prevent="verify" class="primary-btn !w-full !font-medium" :disabled="saving_password">
              <LoadingIcon v-if="saving_password" classList="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import axios from 'axios'
  import { useRoute } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'
  import LoadingIcon from './components/icons/LoadingIcon.vue'

  // store login state in local storage
  // https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  export default {
    name: 'PasswordPage',
    components: {
      LoadingIcon,
    },
    setup() {
      const route = useRoute()
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const email = ref('')
      const token = ref('')
      const page = ref('email')

      onMounted(() => {
        if (route.name === 'Invite' && route.params.email && route.params.token) {
          email.value = route.params.email
          token.value = route.params.token
          page.value = 'verify'
        }
      })

      return {
        email,
        token,
        page,
        notify,
      }
    },
    data() {
      return {
        message: '',
        isSuccess: true,
        password: '',
        requesting_reset_token: false,
        saving_password: false,
      }
    },
    methods: {
      async reset() {
        // get inputs fields
        const email = this.email

        if (email === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // call /auth/token login api with email and password using axios
        try {
          this.requesting_reset_token = true
          const response = await axios.post(
            '/api/auth/send_password_reset',
            { username: email },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            // store login state in local storage
            this.notify.success('Reset token sent to your email 📨')
            this.page = 'verify'
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
          console.log('login failed')
        } finally {
          this.requesting_reset_token = false
        }
      },
      async verify() {
        const email = this.email
        const password = this.password
        const token = this.token

        if (password === '' || token === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // call /api/auth/reset_password api with email and password using axios
        try {
          this.saving_password = true
          const response = await axios.post(
            '/api/auth/reset_password',
            { username: email, password, token },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            // store login state in local storage
            this.notify.success('Password reset successful')
            // Redirect to login page after 3 seconds
            setTimeout(() => {
              this.$router.push({ name: 'Login' })
            }, 3000)
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
        } finally {
          this.saving_password = false
        }
      },
    },
  }
</script>

<style></style>
