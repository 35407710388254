<template>
  <div class="py-4" id="profile" role="tabpanel" aria-labelledby="connection-tab">
    <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Configure Dot</h3>
    <p class="text-sm text-gray-500 dark:text-gray-400 text-left">Modules control what Dot can do.</p>
    <div class="flex flex-row flex-wrap gap-6 mt-6">
      <!-- advanced viz-->
      <ConnectionWrapper connection-name="Advanced Visualizations" :connection-is-active="enabled_advanced_viz">
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="ml-auto w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
            />
          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          Configure charts with natural language. Ask for bar charts, line charts, funnels and more and Dot will display
          the right chart.
          <br />
        </p>

        <ColorPalettePicker />

<!-- Deactivated turbo for now-->
<!--        <div class="flex items-center my-2">-->
<!--          <span class="py-1">Turbo (beta)</span>-->
<!--          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_turbo_viz ? 'On' : 'Off' }}</span>-->
<!--          <button-->
<!--            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"-->
<!--            :class="{-->
<!--              'bg-blue-600 border-blue-300 border-2': enabled_turbo_viz,-->
<!--              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_turbo_viz,-->
<!--            }"-->
<!--            @click="(enabled_turbo_viz = !enabled_turbo_viz), save_modules()"-->
<!--          >-->
<!--            <span-->
<!--              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"-->
<!--              :class="{-->
<!--                'left-[1px]': !enabled_turbo_viz,-->
<!--                'right-[1px]': enabled_turbo_viz,-->
<!--              }"-->
<!--            ></span>-->
<!--          </button>-->
<!--        </div>-->

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_advanced_viz ? 'Always On' : 'Off' }}</span>
<!--          <button-->
<!--            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"-->
<!--            :class="{-->
<!--              'bg-blue-600 border-blue-300 border-2': enabled_advanced_viz,-->
<!--              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_advanced_viz,-->
<!--            }"-->
<!--            @click="(enabled_advanced_viz = !enabled_advanced_viz), save_modules()"-->
<!--          >-->
<!--            <span-->
<!--              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"-->
<!--              :class="{-->
<!--                'left-[1px]': !enabled_advanced_viz,-->
<!--                'right-[1px]': enabled_advanced_viz,-->
<!--              }"-->
<!--            ></span>-->
<!--          </button>-->
        </div>
        <!--        <div class="flex items-center my-2">-->
        <!--          <span class="py-1">Cost per Message</span>-->
        <!--          <span class="ml-auto py-1 text-blue-600 dark:text-blue-300">$0.1</span>-->
        <!--        </div>-->
      </ConnectionWrapper>

      <ConnectionWrapper connection-name="Summary" :connection-is-active="enabled_interpretation">
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          Summarize the retrieved data into a short message. This makes it easier to quickly understand the data.
          <br />
        </p>

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_interpretation ? 'On' : 'Off' }}</span>

          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': enabled_interpretation,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_interpretation,
            }"
            @click="(enabled_interpretation = !enabled_interpretation), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !enabled_interpretation,
                'right-[1px]': enabled_interpretation,
              }"
            ></span>
          </button>
        </div>
        <!--        <div class="flex items-center my-2">-->
        <!--          <span class="py-1">Cost per Message</span>-->
        <!--          <span class="ml-auto py-1 text-blue-600 dark:text-blue-300">$0.1</span>-->
        <!--        </div>-->
      </ConnectionWrapper>

      <ConnectionWrapper connection-name="🧪 Memory" :connection-is-active="enabled_memory">
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          <span class="text-yellow-400 font-mono text-xs">experimental feature</span>
          <br />
          Dot will review previous discussions to compose and revise notes, documenting important details.
          <br /><br />
          By automatically activating notes, Dot will immediately use notes to answer questions (without admin approval).
        </p>

        <div class="flex items-center my-2">
          <span class="py-1">Automatically activate notes</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_live_memory ? 'On' : 'Off' }}</span>
          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': enabled_live_memory,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_live_memory,
            }"
            @click="(enabled_live_memory = !enabled_live_memory), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !enabled_live_memory,
                'right-[1px]': enabled_live_memory,
              }"
            ></span>
          </button>
        </div>

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_memory ? 'On' : 'Off' }}</span>

          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': enabled_memory,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_memory,
            }"
            @click="(enabled_memory = !enabled_memory), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !enabled_memory,
                'right-[1px]': enabled_memory,
              }"
            ></span>
          </button>
        </div>
        <!--        <div class="flex items-center my-2">-->
        <!--          <span class="py-1">Cost per Message</span>-->
        <!--          <span class="ml-auto py-1 text-blue-600 dark:text-blue-300">$0.1</span>-->
        <!--        </div>-->
      </ConnectionWrapper>

      <ConnectionWrapper connection-name="🧪 Self-Critique" :connection-is-active="self_critique">
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
            />
          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          <span class="text-yellow-400 font-mono text-xs">experimental feature</span>
          <br />
          Dot will critique its own answers and provide a confidence score.
          <br />
          <br />
          For now this self-critique is only displayed and not used to change the answer.
        </p>

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ self_critique ? 'On' : 'Off' }}</span>
          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': self_critique,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !self_critique,
            }"
            @click="(self_critique = !self_critique), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !self_critique,
                'right-[1px]': self_critique,
              }"
            ></span>
          </button>
        </div>
      </ConnectionWrapper>


      <ConnectionWrapper connection-name="🧪 Language Processing" :connection-is-active="llm_python">
        <template v-slot:icon>
                    <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
                        <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line>

          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          <span class="text-yellow-400 font-mono text-xs">experimental feature</span>
          <br />
          Dot will have the ability to analyze text fields deeply.
          <br />
        </p>

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ llm_python ? 'On' : 'Off' }}</span>
          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': llm_python,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !llm_python,
            }"
            @click="(llm_python = !llm_python), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !llm_python,
                'right-[1px]': llm_python,
              }"
            ></span>
          </button>
        </div>
      </ConnectionWrapper>


      <ConnectionWrapper connection-name="🧪 Extended Reasoning" :connection-is-active="enabled_more_reasoning">
        <template v-slot:icon>
                    <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
<circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="3"></circle>
          </svg>
        </template>

        <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
          <span class="text-yellow-400 font-mono text-xs">experimental feature</span>
          <br />
          Dot will think more deeply about the questions and each step to answer it. This will make Dot more accurate, but also slower.
          <br />
          With extreme reasoning, Dot will think even more deeply, but will be even slower (not recommended for production).
        </p>

        <div class="flex items-center my-2">
          <span class="py-1">Extreme Reasoning</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_extreme_reasoning ? 'On' : 'Off' }}</span>
          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': enabled_extreme_reasoning,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_extreme_reasoning,
            }"
            @click="(enabled_extreme_reasoning = !enabled_extreme_reasoning), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !enabled_extreme_reasoning,
                'right-[1px]': enabled_extreme_reasoning,
              }"
            ></span>
          </button>
        </div>

        <div class="flex items-center my-2">
          <span class="py-1">Active</span>
          <span class="py-1 ml-auto text-gray-400 dark:text-gray-500">{{ enabled_more_reasoning ? 'On' : 'Off' }}</span>
          <button
            class="ml-4 w-10 h-5 rounded-xl relative flex-shrink-0"
            :class="{
              'bg-blue-600 border-blue-300 border-2': enabled_more_reasoning,
              'bg-gray-200 dark:bg-gray-700 border-blue-200': !enabled_more_reasoning,
            }"
            @click="(enabled_more_reasoning = !enabled_more_reasoning), save_modules()"
          >
            <span
              class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
              :class="{
                'left-[1px]': !enabled_more_reasoning,
                'right-[1px]': enabled_more_reasoning,
              }"
            ></span>
          </button>
        </div>
      </ConnectionWrapper>

      <div class="grow"></div>
    </div>
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { storeToRefs } from 'pinia'
  import { initDrawers } from 'flowbite'
  import ConnectionWrapper from './connectionsTab/ConnectionWrapper.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import ColorPalettePicker from '@/components/ColorPalettePicker.vue'

  export default {
    components: {
      ColorPalettePicker,
      ConnectionWrapper,
    },
    setup() {
      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      return {
        user,
        notify,
      }
    },
    mounted() {
      initDrawers()
      document.title = 'Modules · Settings · Dot'
    },
    created() {
      // Get org information from the API using axios
      axios
        .get('/api/org')
        .then(response => {
          this.enabled_interpretation = response.data.enabled_interpretation
          this.self_critique = response.data.self_critique
          this.enabled_memory = response.data.enabled_memory ?? false
          this.enabled_live_memory = response.data.enabled_live_memory ?? false
          this.enabled_dotml = response.data.enabled_dotml
          // set enforce_dotml to true if it is not set
          this.enforce_dotml = response.data.enforce_dotml ?? true
          this.enabled_advanced_viz = response.data.enabled_advanced_viz ?? false
          this.enabled_turbo_viz = response.data.enabled_turbo_viz ?? false
          this.llm_python = response.data.llm_python ?? false
          this.enabled_more_reasoning = response.data.enabled_more_reasoning ?? false
          this.enabled_extreme_reasoning = response.data.enabled_extreme_reasoning ?? false
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return {
        enabled_interpretation: false,
        self_critique: false,
        llm_python: false,
        enabled_more_reasoning: false,
        enabled_extreme_reasoning: false,
        enabled_memory: false,
        enabled_live_memory: false,
        enabled_advanced_viz: false,
        enabled_turbo_viz: false,
        enabled_dotml: false,
        enforce_dotml: true,
        message: '',
        isSuccess: false,
      }
    },
    methods: {
      async save_modules() {
        try {
          let response = await axios.post(`/api/save_modules`, {
            enabled_interpretation: this.enabled_interpretation ?? false,
            enabled_advanced_viz: this.enabled_advanced_viz ?? false,
            enabled_turbo_viz: this.enabled_turbo_viz ?? false,
            self_critique: this.self_critique ?? false,
            llm_python: this.llm_python ?? false,
            enabled_more_reasoning: this.enabled_more_reasoning ?? false,
            enabled_extreme_reasoning: this.enabled_extreme_reasoning ?? false,
            enabled_memory: this.enabled_memory ?? false,
            enabled_live_memory: this.enabled_live_memory ?? false,
            enabled_dotml: this.enabled_dotml ?? false,
            enforce_dotml: this.enforce_dotml ?? true,
          })
          // console.log(response.data)
          this.notify.success('Saved successfully.')
        } catch (error) {
          console.log(error.response.data)
          this.notify.error(error.response.data.detail)
          this.message = error.response.data.detail
        }
        console.log(this.message)
      },
    },
  }
</script>
