<template>
  <div
    id="toast-notification"
    v-if="isVisible"
    class="fixed mx-auto inset-x-0 top-5 z-50 w-[576px] max-w-[90%] p-4 mb-4 bg-white rounded-xl shadow dark:bg-gray-900"
    :class="{
      'text-red-500 dark:text-red-200': !isSuccess,
      'text-green-500 dark:text-green-200': isSuccess,
    }"
    style="z-index: 9999;"
    role="alert"
    @mouseover="pauseTimeout"
    @mouseleave="startTimeout"
  >
    <div class="flex items-center w-full">
      <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8"
        :class="{
          'text-green-500 bg-green-100 rounded-xl dark:bg-green-800 dark:text-green-200': isSuccess,
          'text-red-500 bg-red-100 rounded-xl dark:bg-red-800 dark:text-red-200': !isSuccess,
        }"
      >
        <svg
          v-if="isSuccess"
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <svg
          v-if="!isSuccess"
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">{{ isSuccess ? 'Check icon' : 'Error icon' }}</span>
      </div>
      <div class="ml-3 text-sm font-normal" v-dompurify-html="message"></div>
      <button
        type="button"
        @click="toggle()"
        class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-xl focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:focus:bg-gray-700 dark:focus:ring-gray-700"
      >
        <span class="sr-only">Close</span>
        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>

    <!-- Timeout bar -->
    <div class="h-1 w-full bg-gray-300 dark:bg-gray-700 rounded-sm mt-3" v-if="isVisible && timeout >= -300">
      <div
        class="h-full rounded-sm transition-[width] duration-1000 ease"
        :class="{
          'bg-green-500 dark:bg-green-800': isSuccess,
          'bg-red-500 dark:bg-red-800': !isSuccess,
        }"
        :style="{ width: timeoutPercentage }"
      ></div>
    </div>
  </div>
</template>

<script>
  import { useGlobalStore } from '@/stores/globalStore'
  import { storeToRefs } from 'pinia'

  export default {
    name: 'NotificationComponent',
    setup() {
      const globalStore = useGlobalStore()
      const { notyMessage, isSuccess, showNoty, triggerNoty } = storeToRefs(globalStore)
      return {
        notyMessage,
        isSuccess,
        showNoty,
        triggerNoty,
      }
    },
    data() {
      return {
        timeout: this.timeout_total,
        timeoutInterval: null,
        isVisible: false,
      }
    },
    // watch isSuccess and if it changes set the timeout to the new value
    watch: {
      isSuccess: {
        handler() {
          this.timeout = this.timeout_total
        },
        immediate: true,
      },
      triggerNoty() {
        this.toggleVisibility = this.showNoty
      },
    },
    methods: {
      toggle() {
        this.isVisible = !this.isVisible
      },
      startTimeout() {
        if (this.isVisible && this.timeout >= -300) {
          this.timeoutInterval = setInterval(() => {
            this.timeout -= 1000
            if (this.timeout <= -300) {
              this.clearTimeout()
              this.isVisible = false
            }
          }, 1000)
        }
      },
      clearTimeout() {
        clearInterval(this.timeoutInterval)
        this.timeoutInterval = null
        this.timeout = this.timeout_total
      },
      pauseTimeout() {
        if (this.timeoutInterval) {
          clearInterval(this.timeoutInterval)
        }
      },
    },
    computed: {
      message() {
        return this.notyMessage
      },
      toggleVisibility: {
        get() {
          return this.isVisible
        },
        set(newValue) {
          this.isVisible = newValue
          if (newValue) {
            this.clearTimeout()
            this.startTimeout()
          } else {
            this.clearTimeout()
          }
        },
      },
      timeout_total() {
        return this.isSuccess ? 4000 : 32000
      },
      timeoutPercentage() {
        return (this.timeout / this.timeout_total) * 100 + '%'
      },
    },
    created() {
      this.startTimeout()
    },
    beforeUnmount() {
      this.clearTimeout()
    },
  }
</script>
