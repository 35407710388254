<template>
  <div
    class="flex items-center gap-3 p-2.5 rounded-12 hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 cursor-pointer border primary-border dark:placeholder-gray-400 dark:text-white transition-colors duration-300 ease-linear"
    @click="toggleChecked"
    role="button"
    tabindex="0"
  >
    <div
      class="rounded-full w-5 h-5 bg-white dark:bg-transparent border flex items-center justify-center"
      :class="{ 'border-blue-700 dark:border-white': isChecked, 'border-gray-300': !isChecked }"
    >
      <div v-if="isChecked" class="rounded-full bg-blue-700 w-3 h-3" />
    </div>
    <label class="text-sm font-medium cursor-pointer">{{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'RadioInput',
    props: {
      checked: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
    },

    computed: {
      isChecked() {
        return this.checked
      },
    },

    methods: {
      toggleChecked() {
        this.$emit('toggle', !this.isChecked)
      },
    },
  }
</script>
