<template>
  <!-- Snowflake connect-->
  <ConnectionWrapper connection-name="Snowflake" :connection-is-active="!!mutableConnection.last_sync">
    <template v-slot:icon>
      <SnowflakeIcon classList="w-6 h-6" />
    </template>

    <div>
      <div class="mb-6">
        <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Account Identifier
        </label>
        <input
          v-model="mutableConnection.account"
          type="text"
          id="snowflake-account"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="<orgname>-<accountname> e.g. ab01000-xy12345"
          required
        />
      </div>
      <div class="mb-6">
        <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
        <input
          v-model="mutableConnection.username"
          type="text"
          id="snowflake-username"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="DOT"
          required
        />
      </div>
      <div class="mb-6">
        <!-- A flowbite toggle to choose between password and key-pair auth -->
        <label class="relative inline-flex items-center" :class="{ 'cursor-pointer': computedEditMode }">
          <span class="relative">
            <input
              type="checkbox"
              v-model="mutableConnection.password_toggle"
              class="sr-only peer"
              :disabled="!computedEditMode"
            />
            <div
              class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
            ></div>
          </span>
          <span class="ms-3 text-sm text-gray-900 dark:text-gray-300">Key-pair</span>
        </label>

        <!-- Password input -->
        <span v-if="!mutableConnection.password_toggle">
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
          <input
            v-model="mutableConnection.password"
            type="password"
            id="snowflake-password"
            :disabled="!computedEditMode"
            autocomplete="off"
            :class="{ 'opacity-50': !computedEditMode }"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </span>
        <!-- Key-pair input -->
        <span v-else>
          <label for="private-key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Private Key
          </label>
          <textarea
            v-model="mutableConnection.private_key"
            id="snowflake-private-key"
            :disabled="!computedEditMode"
            autocomplete="off"
            :class="{ 'opacity-50': !computedEditMode }"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-32 resize-none dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="-----BEGIN RSA PRIVATE KEY-----
  MIIEpAIBAAKCAQEAw0...
  -----END RSA PRIVATE KEY-----"
            required
          ></textarea>

          <label for="passphrase" class="block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Passphrase
          </label>
          <input
            v-model="mutableConnection.private_key_passphrase"
            type="password"
            id="snowflake-passphrase"
            :disabled="!computedEditMode"
            autocomplete="off"
            :class="{ 'opacity-50': !computedEditMode }"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </span>
      </div>
      <div class="mb-6">
        <label for="role" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
        <input
          v-model="mutableConnection.role"
          type="text"
          id="snowflake-role"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="DOT_ROLE"
          required
        />
      </div>
      <div class="mb-6">
        <label for="warehouse" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Warehouse</label>
        <input
          v-model="mutableConnection.warehouse"
          type="text"
          id="snowflake-warehouse"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="COMPUTE_WH"
          required
        />
      </div>
      <div class="flex items-center gap-4 flex-wrap">
        <button
          v-if="editMode"
          @click="editMode = false"
          class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800"
        >
          Cancel
        </button>
        <button
          v-if="hasLastSync && editMode"
          @click.prevent="
            emit('showRemoveModal', {
              ...mutableConnection,
              ...{
                title: mutableConnection.account,
              },
            })
          "
          class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800"
        >
          <span>Remove</span>
        </button>
        <button
          id="connect-snowflake"
          @click.prevent="connect"
          v-if="computedEditMode"
          :disabled="isLoading || !requiredFieldsFilled"
          class="connection-sync-btn"
        >
          <LoadingIcon v-if="isLoading" class="inline w-4 h-4 mr-3 text-white animate-spin" />
          <span v-if="isLoading">Loading...</span>
          <span v-else>Connect</span>
        </button>
        <button v-if="!computedEditMode" @click.stop.prevent="editMode = true" class="connection-edit-btn">Edit</button>
        <button
          id="sync-snowflake"
          @click.prevent="sync"
          v-if="hasLastSync && !computedEditMode"
          class="connection-sync-btn"
        >
          <LoadingIcon class="inline w-4 h-4 mr-3 text-white animate-spin" v-if="isLoading" />
          <span v-if="isLoading">Loading...</span>
          <span v-else>Sync</span>
        </button>
      </div>
      <div
        v-if="hasLastSync"
        v-tooltip="mutableConnection.last_sync"
        @click="emit('showLastLogs', 'snowflake')"
        class="text-xs mt-2 text-gray-500 dark:text-gray-400 hover:underline cursor-pointer"
      >
        Last synced {{ lastSyncDistanceString }}
      </div>
    </div>
  </ConnectionWrapper>
</template>

<script setup>
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import { defineProps, defineEmits, ref, computed, watch } from 'vue'
  import { formatDistanceToNow } from 'date-fns'
  import SnowflakeIcon from '@/components/icons/SnowflakeIcon.vue'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const orgStore = useOrgStore()

  const editMode = ref(false)
  const isLoading = ref(false)

  const emit = defineEmits(['update:connection_info', 'showLastLogs', 'showRemoveModal', 'updateConnections'])

  const props = defineProps({
    connection_info: {
      type: Object,
      default: () => ({}),
    },
    sync_connection: {
      type: Function,
      required: true,
    },
  })

  const mutableConnection = ref(props.connection_info)

  const computedEditMode = computed(() => !mutableConnection.value.last_sync || editMode.value)
  const hasLastSync = computed(() => mutableConnection.value.last_sync)

  const lastSyncDistanceString = computed(() => {
    if (mutableConnection.value.last_sync && mutableConnection.value.last_sync.includes('+')) {
      return formatDistanceToNow(new Date(mutableConnection.value.last_sync), { addSuffix: true })
    } else {
      return ''
    }
  })

  const requiredFieldsFilled = computed(() => {
    return (
      !!mutableConnection.value.account &&
      !!mutableConnection.value.username &&
      (!!mutableConnection.value.password || !!mutableConnection.value.private_key) &&
      !!mutableConnection.value.role &&
      !!mutableConnection.value.warehouse
    )
  })

  const connect = async () => {
    if (!requiredFieldsFilled.value) {
      notify.error('Please fill in all fields')
      return
    }

    isLoading.value = true

    try {
      let response = await axios.post(`/api/connect_snowflake`, mutableConnection.value)
      emit('updateConnections')

      notify.success(response.data)
      orgStore.getConnections(true)

      await props.sync_connection('snowflake', mutableConnection.value.account)

      editMode.value = false
    } catch (error) {
      console.error(error)
      notify.error(error?.response?.data?.detail || 'An error occurred')
    } finally {
      isLoading.value = false
    }
  }

  const sync = async () => {
    isLoading.value = true

    await props.sync_connection('snowflake', mutableConnection.value.account)

    isLoading.value = false
  }
</script>
