<template>
  <div class="content-visibility">
    <div v-if="message.role === 'assistant'">
      <ul
        id="myTab"
        class="flex flex-wrap -mb-px text-sm font-medium text-center justify-between"
        data-tabs-toggle="#myTabContent"
        role="tablist"
      >
        <div class="flex flex-wrap items-center">
          <!-- only show tabs if we have a query -->
          <li v-if="message.json_chart" class="mr-2" role="presentation">
            <button
              id="profile-tab"
              :data-tabs-target="'#profile' + message_index"
              v-if="!eval_mode"
              aria-controls="profile"
              aria-selected="false"
              class="answertab text-xs inline-block font-normal pb-2 mx-2 rounded-t-lg hover:text-blue-500 hover:border-blue-300 dark:hover:text-blue-400"
              role="tab"
              type="button"
            >
              CHART
            </button>
          </li>
          <li v-if="message.html_table" class="mr-2" role="presentation">
            <button
              data-test="data-answer-tab"
              class="answertab text-xs inline-block font-normal pb-2 mx-2 border-transparent rounded-t-lg hover:text-blue-500 hover:border-blue-300 dark:hover:text-blue-400"
              id="dashboard-tab"
              v-if="!eval_mode"
              :data-tabs-target="'#dashboard' + message_index"
              type="button"
              role="tab"
              aria-controls="dashboard"
              aria-selected="false"
            >
              DATA
            </button>
          </li>
          <!-- only show explanation tab title if we have data -->
          <li v-if="message.explanation && message.html_table" class="mr-2" role="presentation">
            <button
              class="answertab text-xs inline-block font-normal pb-2 mx-2 border-transparent rounded-t-lg hover:text-blue-500 hover:border-blue-300 dark:hover:text-blue-400"
              id="explanation-tab"
              :data-tabs-target="'#explanation' + message_index"
              v-if="!eval_mode"
              type="button"
              role="tab"
              aria-controls="explanation"
              aria-selected="false"
            >
              EXPLANATION
            </button>
          </li>
          <li v-if="message.self_critique_result" class="mr-2" role="presentation">
            <button
              class="answertab text-xs inline-block font-normal pb-2 mx-2 border-transparent rounded-t-lg hover:text-blue-500 hover:border-blue-300 dark:hover:text-blue-400"
              id="selfcritique-tab"
              :data-tabs-target="'#self_critique' + message_index"
              v-if="!eval_mode"
              type="button"
              role="tab"
              aria-controls="self_critique"
              aria-selected="false"
            >
              CHECKS
              <span v-if="!Object.values(message.self_critique_result).every(test => test.result)" class="text-red-500">
                ✗
              </span>
              <span class="text-green-500" v-else>✓</span>
            </button>
          </li>
        </div>
        <div class="flex flex-wrap items-center" v-if="!eval_mode">
          <button
            v-tooltip="'This is helpful. Next time do this again!'"
            v-if="
              (message.query && !shared_page && !eval_mode && !is_dashboard) ||
              (is_external_chat && !shared_page && !is_dashboard)
            "
            @click="likeSQL(message.query, message, message_index)"
            :class="
              message.feedback === 'like'
                ? 'text-blue-500 dark:text-blue-400'
                : 'opacity-70 text-dark-text dark:text-white'
            "
            class="ml-auto pb-2 px-1 pt-0 rounded-md text-xs hover:opacity-100 hover:text-blue-500 dark:hover:text-blue-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
              />
            </svg>
          </button>
          <button
            v-tooltip="message.feedback_description || 'This seems wrong.'"
            v-if="
              (message.query && !shared_page && !eval_mode && !is_dashboard) ||
              (is_external_chat && !shared_page && !is_dashboard)
            "
            data-modal-target="dislikeModal"
            data-modal-toggle="dislikeModal"
            @click="dislikeSQL(message, message_index)"
            :class="
              message.feedback === 'dislike'
                ? 'text-red-500 dark:text-red-400'
                : 'opacity-70 text-dark-text dark:text-white'
            "
            class="pb-2 px-1 pt-0 rounded-md text-xs hover:opacity-100 hover:text-red-500 dark:hover:text-red-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384"
              />
            </svg>
          </button>
          <!-- <button
          v-tooltip="is_dashboard ? 'Remove from Dashboard' : 'Add to Dashboard'"
          v-if="message.html_table"
          class="pb-2 px-1 pt-0 rounded-md text-xs hover:opacity-100 hover:text-blue-500 dark:hover:text-blue-400"
          @click="toggleInDashboard(message)"
        >
          <Icon
            :icon="is_dashboard ? 'hugeicons:dashboard-square-remove' : 'hugeicons:dashboard-square-add'"
            class="w-5 h-5"
          />
        </button> -->
          <button
            v-if="!is_dashboard"
            id="dropdownDelayButton"
            :data-dropdown-toggle="`dropdownDelay${message_index}`"
            data-dropdown-delay="100"
            data-dropdown-trigger="hover"
            class="opacity-70 pb-2 px-1 pt-0 text-dark-text dark:text-white rounded-md text-xs hover:opacity-100"
            :class="!message.html_table || shared_or_external ? 'ml-auto' : ''"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </button>

          <!-- Dropdown menu -->
          <div
            :id="`dropdownDelay${message_index}`"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-900"
          >
            <ul class="p-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDelayButton">
              <!-- Download Data -->
              <li>
                <button
                  class="p-2 w-full flex items-center text-dark-text dark:text-white rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800"
                  @click="downloadTableData(message_index, chat_id, message.html_table)"
                  v-if="message.query && message.table_id"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  Download Data
                </button>
              </li>
              <!-- Refresh Data -->
              <li>
                <button
                  v-if="message.query && message.table_id && !shared_or_external"
                  class="p-2 w-full flex items-center text-dark-text dark:text-white rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800"
                  @click="refreshData(message_index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  Refresh Data
                </button>
              </li>
              <!-- Delete Message -->
              <li>
                <button
                  v-if="!shared_or_external && !eval_mode"
                  class="p-2 w-full flex items-center text-red-600 dark:text-red-400 rounded-md text-xs hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-red-700 dark:hover:text-red-300"
                  @click="deleteMessage(message_index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Delete Message
                </button>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>
    <div
      v-if="message.role === 'assistant'"
      id="myTabContent"
      class="rounded-xl"
      :class="{
        'box__bg rounded-xl': message.loading_data,
        'border primary-border': !message.loading_data,
        'mb-4 mt-[1px]': eval_mode,
      }"
    >
      <div
        :class="message.html_table && !eval_mode ? 'hidden' : ''"
        class="bg-white dark:bg-gray-900 rounded-xl pb-2"
        :id="'explanation' + message_index"
        role="tabpanel"
        aria-labelledby="explanation-tab"
      >
        <div class="p-2 pb-1 text-xs text-gray-500 dark:text-gray-400" v-if="message.sources">
          {{ message.sources }}
        </div>
        <div
          class="whitespace-pre-line leading-relaxed text-base transition-max-height duration-300 ease-in-out px-2"
          v-dompurify-html:explanation="markdown_urls_to_links(message.explanation)"
          ref="sqlCode"
          :class="{
            'pb-2 max-h-[500px] overflow-scroll': !show_full_logs[message_index],
            'max-h-[0] overflow-hidden': show_full_logs[message_index],
          }"
        ></div>
        <button
          v-if="message.is_clarifying_question && !shared_or_external"
          @click="$emit('do-your-best')"
          :disabled="message.loading_data || response_loading"
          class="w-max py-1 cursor-pointer border border-gray-300 dark:border-gray-500 rounded-xl ml-2 hover:dark:border-yellow-400 hover:border-yellow-500 px-4"
          data-test="02be6099-bc19-4cc8-b277-145168f61a55"
        >
          Do your best
        </button>
        <h3
          data-test="answer-full-logs"
          v-if="message.logs && !message.is_clarifying_question"
          @click="toggleShowFullLogs(message_index)"
          class="cursor-pointer text-gray-400 dark:text-gray-500 mt-2 mx-2 flex items-center gap-2 hover:dark:text-yellow-400 hover:text-yellow-500"
        >
          Full Logs
          <Icon
            :icon="show_full_logs[message_index] ? 'flowbite:angle-up-outline' : 'flowbite:angle-down-outline'"
            class="w-4 h-4"
          />
        </h3>
        <div
          v-if="message.logs"
          class="px-2 whitespace-pre-line overflow-scroll transition-max-height duration-300 ease-in-out bg-white dark:bg-gray-900"
          :class="{
            'max-h-[500px] mt-2 pt-2 border-t border-gray-200 dark:border-gray-800': show_full_logs[message_index],
            'max-h-[0]': !show_full_logs[message_index],
          }"
          v-dompurify-html="message.logs"
        ></div>
      </div>
      <!-- Lazy loading chart section -->
      <div
        v-if="message.json_chart"
        :id="'profile' + message_index"
        aria-labelledby="profile-tab"
        role="tabpanel"
        class="chart_grand_parent will-change-transform"
        ref="chartContainer"
      >
        <div class="bg-white dark:bg-gray-900 rounded-xl p-2 overflow-y-auto max-h-[600px]">
          <div
            class="pb-2.5 text-base leading-relaxed whitespace-pre-line"
            v-if="message.interpretation"
            v-html="message.interpretation.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')"
          ></div>
          <!-- If there are any found_external_assets, list them with their title and they should link to the url -->
          <div v-if="message.found_external_assets && message.found_external_assets.length" class="pb-2.5">
            Related dashboards:&nbsp;
            <span v-for="(asset, index) in message.found_external_assets" :key="index">
              <a :href="asset.connection_id.replace(/\/+$/, '') + asset.url" target="_blank" class="externalLink">
                {{ asset.table_name }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-external-link"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                </svg>
              </a>
              <span v-if="index < message.found_external_assets.length - 1">,</span>
            </span>
          </div>
          <!-- Lazy load the chart -->
          <div v-if="isChartVisible">
            <!-- If "$schema" attribute in json_chart it is a vega-lite chart -->
            <VegaLiteChart v-if="json_chart && json_chart['$schema']" :vegaLiteJson="json_chart" />
            <!-- Otherwise, it's assumed to be a Plotly chart -->
            <PlotlyChart v-else :chart-config="json_chart" />
          </div>

          <!-- Loader -->
          <div v-else class="flex items-center justify-center h-40">
            <LoadingIcon class="w-5 h-5 text-gray-500 dark:text-gray-300 animate-spin" />
          </div>

          <h3
            v-if="message.filter_text && message.filter_text !== 'No filters applied.'"
            class="cursor-pointer text-gray-400 dark:text-gray-500 mt-2 flex items-center gap-2 hover:dark:text-yellow-400 hover:text-yellow-500"
            @click="toggleShowFullLogs(message_index)"
          >
            <!-- count number of new lines to count filters  -->
            {{ message.filter_text.split('\n').length }} Filter{{
              message.filter_text.split('\n').length > 1 ? 's' : ''
            }}
            <Icon
              :icon="show_full_logs[message_index] ? 'flowbite:angle-up-outline' : 'flowbite:angle-down-outline'"
              class="w-4 h-4"
            />
          </h3>
          <div
            v-if="message.filter_text"
            class="whitespace-pre-line leading-relaxed overflow-scroll transition-max-height duration-300 ease-in-out"
            :class="{
              'max-h-[100px]': show_full_logs[message_index],
              'max-h-[0]': !show_full_logs[message_index],
            }"
            v-dompurify-html="message.filter_text"
          ></div>
        </div>
      </div>

      <div
        v-if="message.html_table"
        :id="'dashboard' + message_index"
        :class="message.json_chart && !eval_mode ? 'hidden' : ''"
        aria-labelledby="dashboard-tab"
        role="tabpanel"
      >
        <div
          class="p-2 text-base leading-relaxed whitespace-pre-line"
          v-if="message.interpretation"
          v-html="message.interpretation.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')"
        ></div>
        <div class="p-2 text-base leading-relaxed whitespace-pre-line" v-if="message.filter_text">
          {{ message.filter_text }}
        </div>
        <div
          class="p-2 dark:bg-gray-900 rounded-xl"
          v-if="message.html_table && message.html_table.trim().startsWith('<p>')"
          v-dompurify-html="message.html_table"
        ></div>
        <DataTable
          class="rounded-xl"
          v-else-if="message.html_table.trim()"
          :table-data-string="message.html_table.trim()"
        />
      </div>
      <div
        v-if="message.self_critique_result"
        :id="'self_critique' + message_index"
        aria-labelledby="selfcritique-tab"
        role="tabpanel"
        class="bg-white dark:bg-gray-900 rounded-xl p-2"
      >
        <div v-for="(value, key) in message.self_critique_result" :key="key" class="mb-4">
          <h3 class="text-md font-bold">
            {{ key.replace('_', ' ').toUpperCase() }}
            <span :class="{ 'text-green-500': value.result, 'text-red-500': !value.result }">
              {{ value.result ? ' ✓' : ' ✗' }}
            </span>
          </h3>
          <div class="mt-1">
            <p v-if="value.explanation" class="mt-2">
              {{ value.explanation }}
            </p>
            <div v-if="value.rubrics && Array.isArray(value.rubrics)" class="mt-2">
              <div v-for="(rubric, index) in value.rubrics" :key="'rubric' + index" class="mt-2">
                <p>
                  <span :class="{ 'text-green-500': rubric.result, 'text-red-500': !rubric.result }">
                    {{ rubric.result ? '✓' : '✗' }}
                  </span>
                  {{ rubric.explanation }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, onMounted, ref, nextTick, watch } from 'vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { initModals, initTabs, initDropdowns } from 'flowbite'
  import DataTable from './DataTable.vue'
  import VegaLiteChart from './VegaLiteChart.vue'
  import PlotlyChart from '@/components/PlotlyChart.vue'
  import { Icon } from '@iconify/vue'
  import axios from '@/axiosInstance'
  import LoadingIcon from './icons/LoadingIcon.vue'

  export default {
    name: 'Answer',
    props: {
      classList: {
        type: String,
        default: '',
      },
      message: {
        type: Object,
        required: true,
      },
      eval_mode: {
        type: Boolean,
        default: false,
      },
      shared_page: {
        type: Boolean,
        default: false,
      },
      is_external_chat: {
        type: Boolean,
        default: false,
      },
      shared_or_external: {
        type: Boolean,
        default: false,
      },
      response_loading: {
        type: Boolean,
        default: false,
      },
      message_index: {
        type: Number,
        required: true,
      },
      messages: {
        type: Array,
        required: false,
      },
      currentChatId: {
        type: String,
        required: true,
        default: '',
      },
      json_chart: {
        type: Object,
        required: false,
      },
      is_dashboard: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      DataTable,
      VegaLiteChart,
      PlotlyChart,
      Icon,
      LoadingIcon,
    },

    setup({ messages, message, is_dashboard, currentChatId }, { emit }) {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      // states
      const local_messages = ref(messages || [])
      const loading_save = ref(false)
      const local_message = ref(message)
      const show_full_logs = ref({})

      function toggleShowFullLogs(message_index) {
        // check if property message_index exists in show_full_logs
        if (show_full_logs.value[message_index] === undefined) {
          show_full_logs.value[message_index] = true
        } else {
          show_full_logs.value[message_index] = !show_full_logs.value[message_index]
        }
      }

      function reloadTabsAndDropdowns() {
        nextTick(() => {
          initTabs()
          initDropdowns()
        })
      }

      function downloadTableData(message_position, chat_id, htmlTableString) {
        const rows = htmlTableString.match(/<tr[^>]*>(.*?)<\/tr>/gs)
        notify.info('Downloading data...')
        if (rows.length >= 5000) {
          axios
            .get('/api/download_big_csv', {
              params: { chat_id, message_position },
            })
            .then(response => {
              // response.data is a blob download it
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              document.body.appendChild(link)
              link.href = url
              link.setAttribute('download', 'data.csv')
              link.click()
              document.body.removeChild(link)
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          downloadCSV(htmlTableString, 'data.csv')
        }
      }

      function tableToCSV(htmlTableString) {
        const rows = htmlTableString.match(/<tr[^>]*>(.*?)<\/tr>/gs)
        let csv = ''
        rows.forEach(row => {
          const headerCells = row.match(/<th[^>]*>(.*?)<\/th>/gs) || []
          const normalCells = row.match(/<td[^>]*>(.*?)<\/td>/gs) || []
          const allCells = [...headerCells, ...normalCells]
          const csvRow = allCells.map(cell => cell.replace(/<[^>]+>/g, '')).join(',')
          csv += csvRow + '\n'
        })
        return csv
      }

      function downloadCSV(htmlTableString, filename) {
        const csv = tableToCSV(htmlTableString)
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)

        // create a hidden link
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      function likeSQL(query_text, message, message_position) {
        let modified_message = message
        if (message.feedback === 'like') {
          modified_message.feedback = ''
        } else {
          modified_message.feedback = 'like'
        }

        // find the message in the messages array and replace it with the modified message
        local_messages.value.splice(message_position, 1, modified_message)
        emit('update-messages', local_messages.value)

        // get last human message, filter out messages that are not from the human
        const last_human_message = local_messages.value.filter(message => message.role === 'user').slice(-1)[0].content

        // call api to save query; todo improve message
        const query = {
          question_text: last_human_message,
          sql_text: query_text,
        }
        axios
          .post(
            '/api/save_query_doc',
            { query: query, table_id: message.table_id, generate_title: true },
            { withCredentials: true }
          )
          .then(() => {
            loading_save.value = false
          })
          .catch(error => {
            console.log(error)
            loading_save.value = false
          })

        // call feedback api

        axios
          .post('/api/feedback', { chat_id: currentChatId, message, message_position }, { withCredentials: true })
          .then(() => {
            loading_save.value = false
          })
          .catch(error => {
            console.log(error)
            loading_save.value = false
          })
      }

      function dislikeSQL(message, message_position) {
        // emit event to parent component
        emit('dislike-sql', { message, message_position })
      }

      function deleteMessage(message_position) {
        axios
          .post('/api/deleteMessage', { chat_id: currentChatId, message_position }, { withCredentials: true })
          .then(() => {
            local_messages.value.splice(message_position, 1)
            emit('update-messages', local_messages.value)

            reloadTabsAndDropdowns()
          })
          .catch(error => {
            console.log(error)
          })
      }

      function refreshData(message_position) {
        if (is_dashboard) {
          return
        }

        const chat_id = currentChatId

        // emit update-refrshing to parent component
        emit('update-refreshing', message_position, true)
        axios
          .post('/api/rerun_query', { chat_id, message_position })
          .then(response => {
            response.data.json_chart = response.data.json_chart
              ? response.data.json_chart.replace('v5.8.0', 'v5.6.1')
              : null

            // emit event to parent component
            emit('refresh-message', {
              message_position,
              html_table: response.data.html_table,
              interpretation: response.data.interpretation,
              timestamp: response.data.timestamp,
              json_chart: response.data.json_chart ? JSON.parse(response.data.json_chart) : null,
            })
          })
          .catch(error => {
            console.log(error)
            emit('highlight-code')
            emit('update-refreshing', message_position, false)
            reloadTabsAndDropdowns()
          })
      }

      function markdown_urls_to_links(text) {
        // Add an icon to external links
        // Use UTF-8 character for external link icon
        const replaced_text = text.replace(
          /\[(.*?)\]\((.*?)\)/g,
          `<a class="externalLink" target="_blank" href="$2">
              $1
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/><polyline points="15 3 21 3 21 9"/><line x1="10" y1="14" x2="21" y2="3"/></svg>
            </a>`
        )

        return replaced_text
      }

      function toggleInDashboard(message) {
        if (is_dashboard) {
          emit('remove-from-dashboard', message)
        } else {
          emit('add-to-dashboard', message)
        }
      }

      const chartContainer = ref(null) // Reference to the chart container
      const isChartVisible = ref(false) // Track if the chart is visible

      onMounted(() => {
        initTabs()
        initDropdowns()
        initModals()

        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              isChartVisible.value = true
              observer.disconnect() // Stop observing once the chart is visible
            }
          })
        })

        if (chartContainer.value) {
          observer.observe(chartContainer.value)
        }
      })

      return {
        downloadTableData,
        likeSQL,
        dislikeSQL,
        deleteMessage,
        refreshData,
        loading_save,
        markdown_urls_to_links,
        local_message,
        toggleInDashboard,
        show_full_logs,
        toggleShowFullLogs,
        chartContainer,
        isChartVisible,
      }
    },
  }
</script>

<style scoped>
  .content-visibility {
    content-visibility: auto;
  }
</style>
