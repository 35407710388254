<template>
  <div
    class="w-full sm:max-w-[20rem] lg:max-w-[24rem] items-start border rounded-xl"
    :class="{
      'self-start': !isExpanded,
      'border-blue-600 dark:border-blue-500 dark:border-opacity-80': connectionIsActive,
      'border-gray-100 dark:border-gray-800': !connectionIsActive,
      '!max-w-full': isInSidebarModal,
    }"
    @dragenter.prevent="
      () => {
        if (connectionName === 'File Upload') {
          isExpanded = true
        }
      }
    "
  >
    <div :id="connectionName" class="flex justify-between items-center cursor-pointer p-4" @click="toggleExpand">
      <div class="flex items-center gap-3">
        <div
          class="text-sm font-mono"
          :class="connectionIsActive ? 'text-blue-800 dark:text-blue-300' : 'text-gray-500 dark:text-gray-400'"
        >
          {{ connectionName }}
        </div>
      </div>
      <div class="flex items-center gap-2">
        <slot name="icon"></slot>
        <AngleRightIcon classList="ml-auto w-5 h-5 text-gray-500 dark:text-gray-400" />
      </div>
    </div>

    <!-- SidebarModal usage -->
    <SidebarModal :show="isExpanded" @close="closeSidebar">
      <template v-slot:title>
        <div class="flex items-center gap-3">
          <slot name="icon"></slot>
          <div class="text-16 font-medium">
            {{ connectionName }}
          </div>
        </div>
      </template>
      <div class="h-[90vh] overflow-y-auto no-scrollbar pb-20">
        <slot />
      </div>
    </SidebarModal>
  </div>
</template>

<script>
  import AngleRightIcon from '../icons/AngleRightIcon.vue'
  import SidebarModal from '../SidebarModal.vue'

  export default {
    name: 'ConnectionWrapper',
    components: {
      AngleRightIcon,
      SidebarModal,
    },
    props: {
      connectionName: {
        type: String,
        required: true,
      },
      connectionIsActive: {
        type: Boolean,
        required: false,
      },
      isInSidebarModal: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        isExpanded: false,
      }
    },

    methods: {
      toggleExpand() {
        this.isExpanded = !this.isExpanded
      },
      closeSidebar() {
        this.isExpanded = false
      },
    },
  }
</script>

<style scoped>
  .slow-ping {
    animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
</style>
